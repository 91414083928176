import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login'
import Dashboard from "../views/Dashboard";
import Streamers from "../views/Streamers";
import StreamerDetail from "../views/StreamerDetail";
import SetPWD from "../views/SetPWD";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Dashboard,
    redirect: '/streamers/',
    children:[
      {
        path: 'streamers/',
        name: 'Streamers',
        component:Streamers,
      },
      {
        path: 'streamers/:id/detail/',
        name: 'StreamerDetail',
        component:StreamerDetail,
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/auth/setPWD',
    name: 'SetPWD',
    component: SetPWD,
  },
]

const router = new VueRouter({
  routes
})

export default router
