import axios from "axios";
import urlResolver from "../api/urls"

class Api {
    getMe() {
        return axios.get(urlResolver.auth.me)
    }

    setPWD(key, userId, pwd) {
        return axios.post(urlResolver.auth.setPWD, {key, userId, pwd})
    }

    streamersList(filter) {
        filter = filter || {}
        return axios.get('streamers/', {params: filter})
    }

    streamerDetail(streamerId) {
        return axios.get('streamers/' + streamerId + '/')
    }

    streamerPatch(streamerId, fieldName, value) {
        let payload = {}
        payload[fieldName] = value
        return axios.patch('streamers/' + streamerId + '/', payload)
    }

    dmaAdminsList() {
        return axios.get('dma-admins/')
    }

    login(username, password) {
        return axios.post(urlResolver.auth.jwt.create, {username, password})
    }

    assignStreamerToAdmin(streamerId, userId) {
        return axios.post('streamers/' + streamerId + '/assign-to/', {user_id: userId})
    }

    jwtRefresh(token) {
        return axios.post(urlResolver.auth.jwt.refresh, {refresh: token});
    }
}

const api = new Api();


export {
    api,
};
