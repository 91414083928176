<template>
  <div if="$store.getters.isAuthenticated">
    <v-app-bar app absolute>
      <v-spacer></v-spacer>
      <v-btn class="v-btn--right" text outlined color="primary" @click="$store.dispatch('logout')">Logout
        <v-icon right>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer bottom color="primary" dark app>
      <v-row class="mt-5">
        <v-col cols="12" class="text-center">
          <span class="text-h4 white--text font-weight-bold">{{
              $store.state.auth.user.username
            }}
            </span>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="text-center font-italic">
          <span class="text-caption white--text">
            <span>role: </span><span class="font-weight-bold">{{ $store.state.auth.user.role }}</span>
            </span>
        </v-col>
      </v-row>
      <v-divider class="mt-5"></v-divider>
      <v-row class="mt-10">
        <v-col cols="12" class="text-center">
          <v-btn class="text" color="white" text @click="$router.push({name:'Home'})">HOME</v-btn>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <v-content>
      <v-container>
        <router-view></router-view>
      </v-container>
      <v-snackbar
          :timeout="3000"
          :value="!!$store.state.snackbar.message"
          :multi-line="true"
          :color="$store.state.snackbar.color"
          @input="$store.commit('resetSnackbar')">
        {{ $store.state.snackbar.message }}
      </v-snackbar>
    </v-content>
  </div>
</template>

<script>
export default {
  name: "Dashboard.vue",
  data() {
    return {
      snackbar: {
        color: null,
        message: null,
      }
    }
  },
  mounted() {
    let self = this
    this.$store.dispatch('checkCookiesOnInit')
    if (!this.$store.getters.isAuthenticated) {
      this.$store.dispatch('refreshToken').then(resp => {
        if (self.$store.state.auth.user.is_dma_user) {
          self.$router.push({name: 'StreamerDetail', params: {'id': self.$store.state.auth.user.streamer}})
        }
      })
    }
  },
  methods: {},
}
</script>

<style scoped>

</style>