import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import {api} from "./api/api";
import validationRules from "./utils/rules"
import axios from "axios"
import axiosSetUp from "./axios/axiosSetUp";
import ROLES from "./data/roles";
import timeUtils from './utils/time'
Vue.config.productionTip = false
Vue.prototype.$api = api
Vue.prototype.$validationRules = validationRules
Vue.prototype.$ROLES = ROLES
Vue.prototype.$timeUtils = timeUtils
axiosSetUp()
new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
