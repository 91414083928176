<template>
  <v-app>
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4" lg="4">
            <v-form ref="form" @submit.prevent="submit">
              <v-card>
                <v-card-title>Change password</v-card-title>
                <v-card-text>
                  <v-col>
                    <v-row>
                      <v-text-field label="New Password" type="password" v-model="form.pwd"
                                    :rules="[$validationRules.required]"></v-text-field>
                    </v-row>
                  </v-col>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="primary" x-large type="submit">Submit</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "SetPWD",
  data() {
    return {
      form: {
        pwd: null,
      }
    }
  },
  computed: {
    key() {
      return this.$route.query.key
    },
    userId() {
      return this.$route.query.userId
    },
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) return
      let payload = {
        pwd: null,
        key: null,
      }
      this.$api.setPWD(this.key, this.userId, this.form.pwd).then(resp => {

        this.$router.push({name: 'Login'})
      }).catch(err => {
        alert(err.response.data.detail)
      })
    }
  }
}
</script>

<style scoped>

</style>