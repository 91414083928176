import axios from "axios";
import store from "../store";
import router from "../router";

import urlResolver from '../api/urls'
export default function axiosSetUp() {
    // point to your API endpoint
    axios.defaults.baseURL = urlResolver.root;
    // Add a request interceptor
    axios.interceptors.request.use(
        function (config) {
            console.log('REQUEST')
            // Do something before request is sent
            const token = store.state.auth.accessToken;
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        function (error) {
            // Do something with request error
            return Promise.reject(error);
        }
    );

    // Add a response interceptor
    axios.interceptors.response.use(
        function (response) {
            console.log(response)
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        },
        async function (error) {
            const originalRequest = error.config;
            console.log('Error response')
            console.log('STATUS: '+error.response.status)
            console.log('URL: '+originalRequest.url)
            console.log('IS RETRY: '+ originalRequest._retry)
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error

            if (error.response.status === 401 && originalRequest.url.includes("auth/jwt/refresh")) {
                await store.dispatch("logout");
                await router.push({name: "login"});
                return Promise.reject(error);
            } else if (error.response.status === 401 && !originalRequest._retry) {
                if (store.state.auth.refreshToken) {
                    console.log('Refreshing token...')
                    originalRequest._retry = true;
                    await store.dispatch("refreshToken");
                    return axios(originalRequest);
                }else{
                    router.push({name: "Login"})
                    return Promise.reject(error);
                }

            }
            console.log('Unexpected error')
            return Promise.reject(error);
        }
    );
}

