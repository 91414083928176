import Vue from 'vue'
import Vuex from 'vuex'
import router from "../router";
import {api} from "../api/api";
import cookie from 'vue-cookies'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        auth: {
            user: {
                id: null,
                username: null,
                role: null,
                is_dma_manager:null,
                is_dma_admin:null,
                is_dma_user:null,
                streamer:null,
            },
            accessToken: null,
            refreshToken: null,
        },
        adminsFlatList: [],
        snackbar: {
            color: null,
            message: null,
        }
    },
    getters: {
        isAuthenticated: state => {
            return !!state.auth.user.id
        },
    },
    mutations: {
        setRefreshToken: function (state, refreshToken) {
            state.auth.refreshToken = refreshToken;
            cookie.set('refreshToken', refreshToken)
        },
        setAccessToken: function (state, accessToken) {
            state.auth.accessToken = accessToken;
            cookie.set('accessToken', accessToken)
        },
        setAuthUser: function (state, user) {
            state.auth.user = user;
        },
        setAdminsFlatList: function (state, adminsFlatList) {
            state.adminsFlatList = adminsFlatList;
        },
        showSuccessSnackbar(state, message) {
            state.snackbar.color = 'green'
            state.snackbar.message = message
        },
        showErrorSnackbar(state, message) {
            state.snackbar.color = 'red'
            state.snackbar.message = message
        },
        resetSnackbar(state) {
            state.snackbar.color = null
            state.snackbar.message = null
        }
    },
    actions: {
        loadAdminsFlatList({commit}) {
            api.dmaAdminsList().then(resp => {
                commit('setAdminsFlatList', resp.data)
            })
        },
        loadInitial({dispatch}) {
            dispatch('loadAdminsFlatList')
        },
        logout({commit}) {
            commit('setAccessToken', null)
            commit('setRefreshToken', null)
            commit('setAuthUser', null)
            router.push({'name': 'Login'})
        },
        refreshToken: async ({state, commit, dispatch}, successRedirectUrlName) => {
            console.log('Refreshing JWT Token...')
            try {
                await api.jwtRefresh(state.auth.refreshToken).then(response => {
                    console.log('JWT Token refreshed')
                    commit('setAccessToken', response.data.access)
                    commit('setAuthUser', response.data.user)
                    if (successRedirectUrlName) {
                        router.push({name: successRedirectUrlName})
                    } else {
                        router.push({name: 'Home'})
                    }

                }).catch(err => {
                    console.log('error resp')
                    console.log(err)
                    dispatch('logout')
                });
            } catch (e) {
                console.error(e)
                console.log('error')
                dispatch('logout')
            }
        },
        checkCookiesOnInit({commit}) {
            let accessToken = cookie.get('accessToken')
            let refreshToken = cookie.get('refreshToken')
            if (accessToken && refreshToken) {
                commit('setAccessToken', accessToken)
                commit('setRefreshToken', refreshToken)
            }
        }

    },
    modules: {}
})
