var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.$store.state.adminsFlatList,"item-text":"username","item-value":"id","label":"Assigned to","clearable":""},model:{value:(_vm.filter.assigned_to),callback:function ($$v) {_vm.$set(_vm.filter, "assigned_to", $$v)},expression:"filter.assigned_to"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Search"},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.streamers,"server-items-length":_vm.total,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"footer-props":{
            'items-per-page-options':[20,25,30],
            // 'disable-items-per-page': true,
          }},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
          var item = ref.item;
return [_c('v-img',{key:item.id,attrs:{"max-height":80,"aspect-ratio":"1","src":item.avatar}})]}},{key:"item.stats_normal_minutes",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$timeUtils.convertMinutesToHoursAndMinutes(item.stats_normal_minutes))+" ")]}},{key:"item.stats_game_minutes",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$timeUtils.convertMinutesToHoursAndMinutes(item.stats_game_minutes))+" ")]}},{key:"item.view",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({name:'StreamerDetail', params:{id:item.id}})}}},[_vm._v("View "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-eye")])],1)]}},{key:"item.assigned_to",fn:function(ref){
          var item = ref.item;
return [(item.assigned_to)?[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.assigned_to_detail.username)+" "),_c('v-icon',{attrs:{"color":"orange","small":""},on:{"click":function($event){return _vm.showAssignToDialog(item.id)}}},[_vm._v("mdi-pencil")])],1)]:[_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.showAssignToDialog(item.id)}}},[_vm._v("Assign to "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-account-circle")])],1)]]}}])}),_c('v-row',{attrs:{"justify":"center"}},[_c('AssignToDialog',{key:_vm.assignToDialogStreamerId,attrs:{"streamer-id":_vm.assignToDialogStreamerId},model:{value:(_vm.assignToDialog),callback:function ($$v) {_vm.assignToDialog=$$v},expression:"assignToDialog"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }