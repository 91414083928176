export default {
    required: v => !(!v && v !== 0) || "This field is required",
    email: v => /.+@.+/.test(v) || 'E-mail must be valid',
    minLength(n) {
        return v => (!v || v.length >= n) || 'Min length is ' + n
    },
    maxLength(n) {
        return v => (!v || v.length <= n) || 'Max length is ' + n
    },
    exactLength(n) {
        return v => (!v || v.length === n) || 'Length must be ' + n
    },
    decimalInput: v => (!v || /^-?\d*(\.\d{1,2})?$/.test(v)) || 'Number is invalid',
    url: v => !v || /https?:[0-9]*\/\/[\w!?/\+\-_~=;\.,*&@#$%\(\)\'\[\]]+/.test(v) || 'URL must be valid',
    minValue(n) {
        return v => (!v || Number(v) >= n) || 'Min value is ' + n
    },
    maxValue(n) {
        return v => (!v || Number(v) <= n) || 'Max value is ' + n
    },
}