<template>

  <v-row v-if="streamer" class="my-12 mx-3">
    <v-col cols="12" md="6">



      <v-row>
        <v-col cols="6">
          <v-img class="align-md-start" height="200" contain :src="streamer.avatar"/>
        </v-col>
      </v-row>
      <v-row class="mt-10">
        <v-col cols="6">Mico ID</v-col>
        <v-col cols="6">{{ streamer.mico_id }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="6">Nickname</v-col>
        <v-col cols="6">{{ streamer.nickname }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="6">Country</v-col>
        <v-col cols="6">{{ streamer.country }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="6">WhatsApp Number</v-col>
        <template v-if="showUpdateWhatsAppNumberField && ($store.state.auth.user.is_dma_admin || $store.state.auth.user.is_dma_manager)">

          <div class="d-flex">
            <v-text-field :error-messages="fieldWhatsAppNumberErrors" v-model="streamer.whatsapp_number"
                          label="WhatsApp Number"
            ></v-text-field>
            <v-btn text large color="primary" @click="patchWhatsAppNumber">Save</v-btn>
          </div>
        </template>
        <template v-else>
          <v-col cols="6">{{ streamer.whatsapp_number }}
            <v-icon v-if="$store.state.auth.user.is_dma_admin || $store.state.auth.user.is_dma_manager" color="orange" small @click="showUpdateWhatsAppNumberField = true">
              {{ streamer.whatsapp_number ? 'mdi-pencil' : 'mdi-plus-circle' }}
            </v-icon>
          </v-col>
        </template>
      </v-row>
      <v-row>
        <v-col cols="6">Payoneer Email</v-col>
        <template v-if="showUpdatePayoneerEmailField && ($store.state.auth.user.is_dma_admin || $store.state.auth.user.is_dma_manager)">

          <div class="d-flex">
            <v-text-field :error-messages="fieldPayoneerEmailErrors" v-model="streamer.payoneer_email"
                          label="Payoneer Email"
            ></v-text-field>
            <v-btn text large color="primary" @click="patchPayoneerEmail">Save</v-btn>
          </div>
        </template>
        <template v-else>
          <v-col cols="6">{{ streamer.payoneer_email }}
            <v-icon v-if="$store.state.auth.user.is_dma_admin || $store.state.auth.user.is_dma_manager" color="orange" small @click="showUpdatePayoneerEmailField = true">
              {{ streamer.payoneer_email ? 'mdi-pencil' : 'mdi-plus-circle' }}
            </v-icon>
          </v-col>
        </template>
      </v-row>
      <v-row class="mt-8"></v-row>
      <v-row>
        <v-col cols="6">Monthly Income</v-col>
        <v-col cols="6">{{ streamer.stats_monthly_income }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="6">Normal Days</v-col>
        <v-col cols="6">{{ streamer.stats_normal_days }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="6">Normal Hours</v-col>
        <v-col cols="6">{{ $timeUtils.convertMinutesToHoursAndMinutes(streamer.stats_normal_minutes) }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="6">Game Days</v-col>
        <v-col cols="6">{{ streamer.stats_game_days }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="6">Game Hours</v-col>
        <v-col cols="6">{{ $timeUtils.convertMinutesToHoursAndMinutes(streamer.stats_game_minutes) }}</v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="6">
      <span class="text-h5">Recordings</span>
      <v-data-table class="my-5" :headers="recordingsTableHeaders" :items="streamer.recordings" :items-per-page="5">
        <template v-slot:item.diamonds="{ item }">
          {{ item.diamonds }}
          <v-icon x-small color="blue">mdi-diamond</v-icon>
        </template>
        <template v-slot:item.date="{ item }">
          {{ isoToDate(item.start_dt) }}
        </template>
        <template v-slot:item.start_dt="{ item }">
          {{ isoToTime(item.start_dt) }}
        </template>
        <template v-slot:item.end_dt="{ item }">
          {{ isoToTime(item.end_dt) }}
        </template>
        <template v-slot:item.duration_minutes="{ item }">
          {{ $timeUtils.convertMinutesToHoursAndMinutes(item.duration_minutes) }}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import {format} from "date-fns";

export default {
  name: "StreamerDetail",
  components: {},
  props: {},
  data() {
    return {
      streamer: null,
      showUpdatePayoneerEmailField: false,
      showUpdateWhatsAppNumberField: false,
      fieldPayoneerEmailErrors: null,
      fieldWhatsAppNumberErrors: null,
    }
  },
  computed: {
    recordingsTableHeaders() {
      return [
        {text: 'Date', value: 'date'},
        {text: 'Start', value: 'start_dt'},
        {text: 'End', value: 'end_dt'},
        {text: 'Duration', value: 'duration_minutes'},
        {text: 'Type', value: 'type'},
        {text: 'Diamonds', value: 'diamonds'},
      ]
    },
    streamerId() {
      return this.$route.params.id
    },
  },
  mounted() {
    this.loadStreamer()
  },
  methods: {
    patchPayoneerEmail() {
      var self = this
      this.$api.streamerPatch(this.streamerId, 'payoneer_email', this.streamer.payoneer_email).then(resp => {
        self.$store.commit('showSuccessSnackbar', 'Streamer has been updated')
        self.showUpdatePayoneerEmailField = false
        self.fieldPayoneerEmailErrors = null
      }).catch(err => {
        self.$store.commit('showErrorSnackbar', 'Something went wrong')
        self.fieldPayoneerEmailErrors = err.response.data.payoneer_email
      })
    },
    patchWhatsAppNumber() {
      var self = this
      this.$api.streamerPatch(this.streamerId, 'whatsapp_number', this.streamer.whatsapp_number).then(resp => {
        self.$store.commit('showSuccessSnackbar', 'Streamer has been updated')
        self.showUpdateWhatsAppNumberField = false
        self.fieldWhatsAppNumberErrors = null
      }).catch(err => {
        self.$store.commit('showErrorSnackbar', 'Something went wrong')
        self.fieldWhatsAppNumberErrors = err.response.data.whatsapp_number
      })
    },
    isoToDate(isoDatetime) {
      var date = new Date(isoDatetime)
      return date.toLocaleDateString()
    },
    isoToTime(isoDatetime) {
      var date = new Date(isoDatetime)
      return date.toLocaleTimeString([], {timeStyle: 'short'})
    },
    loadStreamer() {
      let self = this
      this.$api.streamerDetail(this.streamerId).then(resp => {
        self.streamer = resp.data
      }).catch(err => {
        alert('Error loading streamer')

      })
    }
  }
}
</script>

<style scoped>

</style>