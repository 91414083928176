<template>
  <v-dialog
      :value="value"
      @input="$emit('input',$event)"
      max-width="600px">
    <v-card>
      <v-form ref="form">
      <v-card-title>
        <span class="text-h5">User Profile</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
                cols="12">
              <v-autocomplete
                  :items="$store.state.adminsFlatList"
                  item-text="username"
                  item-value="id"
                  label="Admins"
                  v-model="userId"
                  clearable
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn block color="primary" @click="submit">Assign</v-btn>
      </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {api} from "../api/api";

export default {
  name: 'AssignToDialog',
  props: {
    value: {},
    streamerId: {},
  },
  data() {
    return {
      userId: null,
    }
  },
  watch: {
    value: {
      handler(_new, old) {
        this.$emit('input', this.value)
      },
      immediate: false,
    }
  },
  methods: {
    reset() {
      this.userId = null
    },
    submit() {
      if (!this.$refs.form.validate()) return
      let self = this
      api.assignStreamerToAdmin(this.streamerId, this.userId).then(resp => {
        self.$store.commit('showSuccessSnackbar', 'Streamer has been assigned to admin')
        self.$emit('input', false)
      }).catch(err => {
        self.$store.commit('showErrorSnackbar', 'Something went wrong')
        self.$emit('input', false)
      })
    }
  }

}
</script>
