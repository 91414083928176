<template>
  <v-app>
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4" lg="4">
            <v-form ref="form" @submit.prevent="submit">
              <v-card>
                <v-card-title>Login</v-card-title>
                <v-card-text>
                  <v-col>
                    <v-row>
                      <v-text-field label="Username" v-model="form.username"
                                    :rules="[$validationRules.required]"></v-text-field>
                    </v-row>
                    <v-row>
                      <v-text-field label="Password" type="password" v-model="form.password"
                                    :rules="[$validationRules.required]"></v-text-field>
                    </v-row>
                  </v-col>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="primary" x-large type="submit" >Submit</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      form: {
        username: null,
        password: null,
      }
    }
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) return
      this.$api.login(this.form.username, this.form.password).then(resp => {
        let accessToken = resp.data.access
        let refreshToken = resp.data.refresh
        let authUser = resp.data.user
        this.$store.commit('setAccessToken', accessToken)
        this.$store.commit('setRefreshToken', refreshToken)
        this.$store.commit('setAuthUser', authUser)
        this.$router.push({name:'Home'})
      }).catch(err => {
        alert(err.response.data.detail)
      })
    }
  }
}
</script>

<style scoped>

</style>