<template>
  <div>
    <v-row>
      <v-col cols="12" md="2">
        <v-autocomplete
            :items="$store.state.adminsFlatList"
            item-text="username"
            item-value="id"
            label="Assigned to"
            v-model="filter.assigned_to"
            clearable
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="filter.search" label="Search"></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
            :headers="tableHeaders"
            :items="streamers"
            :server-items-length="total"
            :page.sync="page"
            :items-per-page.sync="itemsPerPage"
            :footer-props="{
              'items-per-page-options':[20,25,30],
              // 'disable-items-per-page': true,
            }">

          <template v-slot:item.avatar="{ item }">
            <v-img :key="item.id" :max-height="80" aspect-ratio="1"
                   :src="item.avatar"></v-img>
          </template>
          <template v-slot:item.stats_normal_minutes="{ item }">
            {{ $timeUtils.convertMinutesToHoursAndMinutes(item.stats_normal_minutes) }}
          </template>
          <template v-slot:item.stats_game_minutes="{ item }">
            {{ $timeUtils.convertMinutesToHoursAndMinutes(item.stats_game_minutes) }}
          </template>

          <template v-slot:item.view="{ item }">
            <v-btn outlined color="primary" @click="$router.push({name:'StreamerDetail', params:{id:item.id}})">View
              <v-icon right>mdi-eye</v-icon>
            </v-btn>
          </template>
          <!--      <template v-slot:item.assigned_to="{ item }">-->
          <!--        <template v-if="item.assigned_to"><span class="text-caption">Assigned to: {{ item.assigned_to_detail.username }}</span></template>-->
          <!--        <v-btn outlined color="primary" @click="showAssignToDialog(item.id)">{{ item.assigned_to ? 'Reassign' : 'Assign to' }}-->
          <!--          <v-icon right>mdi-account-circle</v-icon>-->
          <!--        </v-btn>-->
          <!--        -->
          <!--      </template>-->
          <template v-slot:item.assigned_to="{ item }">
            <template v-if="item.assigned_to"><span class="text-caption">{{ item.assigned_to_detail.username }} <v-icon
                color="orange" small @click="showAssignToDialog(item.id)">mdi-pencil</v-icon></span></template>
            <template v-else>
              <v-btn outlined color="primary" @click="showAssignToDialog(item.id)">Assign to
                <v-icon right>mdi-account-circle</v-icon>
              </v-btn>
            </template>

          </template>
        </v-data-table>
        <v-row justify="center">
          <AssignToDialog :key="assignToDialogStreamerId" v-model="assignToDialog"
                          :streamer-id="assignToDialogStreamerId"></AssignToDialog>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AssignToDialog from "../components/AssignToDialog";

export default {
  name: "Streamers",
  components: {AssignToDialog},
  data() {
    return {
      streamers: [],
      assignToDialog: false,
      assignToDialogStreamerId: null,
      filter: {
        search: null,
        assigned_to: null,
      },
      page: 1,
      total: -1,
      itemsPerPage: 20,
    }
  },
  computed: {
    tableHeaders() {
      let _headers = [
        {text: 'User ID', value: 'mico_id', sortable: false},
        {text: 'Avatar', value: 'avatar', sortable: false},
        {text: 'Nickname', value: 'nickname', sortable: false},
        {text: 'Country', value: 'country', sortable: false},
        {text: 'Normal Days', value: 'stats_normal_days', sortable: false},
        {text: 'Normal Hours', value: 'stats_normal_minutes', sortable: false},
        {text: 'Game Hours', value: 'stats_game_minutes', sortable: false},
        {text: '', value: 'view', sortable: false, width: 50},
        {
          text: 'Assigned to',
          value: 'assigned_to',
          sortable: false,
          width: 50,
          hide: !this.$store.state.auth.user.is_dma_manager
        },
      ]

      return _headers.filter(x => !x.hide)

    },
  },
  mounted() {
    this.loadStreamers()
  },
  watch: {

    assignToDialog: {
      handler() {
        if (!this.assignToDialog) {
          this.loadStreamers()
        }
      }
    },
    page: {
      handler() {
        this.loadStreamers()
      }
    },
    itemsPerPage: {
      handler() {
        this.loadStreamers()
      }
    },
    filter: {
      handler(_new, old) {
        this.page = 1
        this.loadStreamers()
      },
      deep: true,
    }
  },
  methods: {
    loadStreamers() {
      var self = this
      let queryParams = JSON.parse(JSON.stringify(this.filter))
      queryParams['page'] = this.page
      queryParams['page_size'] = this.itemsPerPage
      this.$api.streamersList(queryParams).then(resp => {
        self.streamers = resp.data.results
        self.total = resp.data.count
      })
    },
    showAssignToDialog(streamerId) {
      this.assignToDialogStreamerId = streamerId
      this.assignToDialog = true
    }
  }
}
</script>

<style scoped>

</style>