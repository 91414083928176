const _urljoin = require("url-join");

function urljoin() {
    // adds trailing slash if not present
    let stringedArguments = Array(...arguments).map(x => String(x));
    let url = _urljoin(...stringedArguments);
    return !url.endsWith("/") ? _urljoin(url, "/") : url;
}

const apiRoot = process.env.VUE_APP_DMA_API_URL || 'http://127.0.0.1:8000'

export default {
    root: apiRoot,
    auth: {
        jwt: {
            create: urljoin(apiRoot, "auth/jwt/login/"),
            refresh: urljoin(apiRoot, "auth/jwt/refresh/"),
        },
        loginPage: "/login",
        me: urljoin(apiRoot,"users/me/"),
        setPWD: urljoin(apiRoot,"auth/set-pwd/")
    }
}

