<template>
  <v-app id="inspire">
    <router-view></router-view>
  </v-app>
</template>
<script>

export default {
  data: () => ({}),
  mounted() {
    this.$store.dispatch('loadInitial')
  }
}
</script>
